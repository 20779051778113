import React, { useState, useEffect } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import {
  getUserProfileData,
  deleteUserAccount,
  getJwtToken,
} from "../actions/actions";
import { useNavigate } from "react-router-dom";

const UserProfileView = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [sso, setSso] = useState("");
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = getJwtToken();
    if (!jwtToken) navigate("/login");

    getUserProfileData()
      .then((response) => {
        if (response.status === 200) {
          response.json().then((userData) => {
            setName(userData.name || " - ");
            setEmail(userData.email || " - ");
            setPhoneNumber(userData.phoneNumber || " - ");
            setNin(userData.nin || " - ");
            setSso(
              userData.sso && userData.sso.length > 0
                ? userData.sso.join(", ")
                : " - ",
            );
          });
        } else {
          setMessage({
            type: "danger",
            text: "Failed to fetch user profile",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
        setMessage({
          type: "danger",
          text: "An error occurred while fetching user data",
        });
      });
  }, []);

  const handleDeleteAccount = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone.",
    );

    if (isConfirmed) {
      deleteUserAccount()
        .then((response) => {
          if (response.status === 200) {
            setMessage({
              type: "success",
              text: "Your account has been deleted",
            });
            setTimeout(() => {
              localStorage.removeItem("jwtToken");
              navigate("/signup");
            }, 3000);
          } else {
            setMessage({
              type: "danger",
              text: "Failed to delete your account",
            });
          }
        })
        .catch((error) => {
          console.error("Error deleting account:", error);
          setMessage({
            type: "danger",
            text: "An error occurred while deleting your account",
          });
        });
    }
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            disabled
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            disabled
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>

        <Form.Group controlId="formPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            value={phoneNumber}
            disabled
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>

        <Form.Group controlId="formNIN">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            value={nin}
            disabled
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>

        <Form.Group controlId="formState">
          <Form.Label>SSO</Form.Label>
          <Form.Control
            type="text"
            value={sso}
            disabled
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>

        <Button
          variant="danger"
          onClick={handleDeleteAccount}
          className="w-100 mt-4"
          style={{ padding: "10px", fontWeight: "bold" }}
        >
          Delete my Account
        </Button>
      </Form>
    </>
  );
};

export default UserProfileView;
