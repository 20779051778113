import React from "react";
import { useNavigate } from "react-router-dom";

const PoliceActView = () => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/chat");
  };

  return (
    <div style={{ marginBottom: "20px", padding: "20px" }}>
      <div style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "20px",
        backgroundColor: "#f9f9f9"
      }}>
        <h4 className="my-2"><strong>The Nigerian Police Act 2020</strong></h4>
        <h6>Enacted to provide for the framework for the Nigerian Police Force and ensure cooperation and partnership between the police and communities in maintaining peace and security.</h6>
        <p>
          <strong>CHAPTER I - General Provisions</strong><br />
          <strong>1. General Objectives of the Act</strong><br />
          (a) Accountability and transparency;<br />
          (b) Protection of human rights and fundamental freedoms;<br />
          (c) Partnership with other security agencies.
        </p>
        <p>
          <strong>2. Specific Objectives</strong><br />
          (a) Provide a police force that is more responsive to the needs of the general public;<br />
          (b) Reposition the police force to safeguard fundamental rights;<br />
          (c) Bring about a positive change in public perception of the police force.
        </p>
        <p>
          <strong>3. Establishment of the Police Force</strong><br />
          The Nigerian Police Force shall be established and organized under this Act to carry out its functions in maintaining public safety, law and order.
        </p>
        <button
          onClick={handleMoreClick}
          style={{ margin:"auto", padding: "10px 20px", backgroundColor: "#708914", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer", alignContent: "center" }}>
          Read More
        </button>
      </div>
    </div>
  );
};

export default PoliceActView;
