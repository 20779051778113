import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { createPoll, editPoll } from "../actions/actions";

const CreateAndEditPollForm = ({ selectedPoll }) => {
  const [pollData, setPollData] = useState({
    title: selectedPoll?.title || "",
    description: selectedPoll?.description || "",
    start_date:
      selectedPoll?.start_time.split(" ")[0] +
        "T" +
        selectedPoll?.start_time.split(" ")[1] || "",
    end_date:
      selectedPoll?.end_time.split(" ")[0] +
        "T" +
        selectedPoll?.end_time.split(" ")[1] || "",
    status: selectedPoll?.status || "draft",
    allow_multiple_votes: selectedPoll?.allow_multiple_votes || false,
    options: selectedPoll?.options || [{ title: "", description: "" }],
    type: selectedPoll?.type || "poll",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedOptions = [...pollData.options];
    updatedOptions[index][name] = value;
    setPollData({ ...pollData, options: updatedOptions });
  };

  const addOption = () => {
    setPollData({
      ...pollData,
      options: [...pollData.options, { title: "", description: "" }],
    });
  };

  const handleDeleteOption = (index) => {
    setPollData((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedStartDate = new Date(pollData.start_date).toLocaleString(
      "en-GB",
      { timeZone: "UTC" },
    );
    const formattedEndDate = new Date(pollData.end_date).toLocaleString(
      "en-GB",
      { timeZone: "UTC" },
    );

    createPoll(
      pollData.title,
      pollData.description,
      formattedStartDate,
      formattedEndDate,
      pollData.status,
      pollData.options,
      pollData.allow_multiple_votes,
      pollData.type,
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        setSuccess(true);
        setError("");
        setPollData({
          title: "",
          description: "",
          start_date: "",
          end_date: "",
          status: "draft",
          allow_multiple_votes: false,
          options: [{ title: "", description: "" }],
          type: "poll",
        });
      })
      .catch((error) => {
        if (error instanceof Response)
          error.json().then((errorMessage) => {
            setError(errorMessage.message);
          });
        else
          setError(
            "An error occurred while creating poll, please try again later.",
          );
        setSuccess(false);
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const formattedStartDate = new Date(pollData.start_date).toLocaleString(
      "en-GB",
      { timeZone: "UTC" },
    );
    const formattedEndDate = new Date(pollData.end_date).toLocaleString(
      "en-GB",
      { timeZone: "UTC" },
    );

    editPoll(
      selectedPoll.id,
      pollData.title,
      pollData.description,
      formattedStartDate,
      formattedEndDate,
      pollData.status,
      pollData.options,
      pollData.allow_multiple_votes,
      pollData.type,
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        setSuccess(true);
        setError("");

        if (!selectedPoll) {
          setPollData({
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            status: "draft",
            allow_multiple_votes: false,
            options: [{ title: "", description: "" }],
            type: "poll",
          });
        }
      })
      .catch((error) => {
        if (error instanceof Response)
          error.json().then((errorMessage) => {
            setError(errorMessage.message);
          });
        else
          setError(
            "An error occurred while editing poll, please try again later.",
          );
        setSuccess(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {!selectedPoll && <h4 className="my-4">Create New Poll/Vote</h4>}

      {success && <Alert variant="success">Changes saved successfully</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group controlId="formTitle">
        <Form.Label>Title*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter poll title"
          name="title"
          value={pollData.title}
          onChange={(e) => setPollData({ ...pollData, title: e.target.value })}
          className="mb-3"
          required
        />
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter poll description"
          name="description"
          value={pollData.description}
          onChange={(e) =>
            setPollData({ ...pollData, description: e.target.value })
          }
          className="mb-3"
        />
      </Form.Group>

      <div className="row">
        <div className="col-md-4 mb-3">
          <Form.Group controlId="formStartDate">
            <Form.Label>Start Date*</Form.Label>
            <Form.Control
              type="datetime-local"
              name="start_date"
              value={pollData.start_date}
              onChange={(e) =>
                setPollData({ ...pollData, start_date: e.target.value })
              }
              required
            />
          </Form.Group>
        </div>
        <div className="col-md-4 mb-3">
          <Form.Group controlId="formEndDate">
            <Form.Label>End Date*</Form.Label>
            <Form.Control
              type="datetime-local"
              name="end_date"
              value={pollData.end_date}
              onChange={(e) =>
                setPollData({ ...pollData, end_date: e.target.value })
              }
              required
            />
          </Form.Group>
        </div>
        <div className="col-md-2 mb-3">
          <Form.Group controlId="formStatus">
            <Form.Label>Status*</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={pollData.status}
              onChange={(e) =>
                setPollData({ ...pollData, status: e.target.value })
              }
              required
            >
              <option value="active">Active</option>
              <option value="draft">Draft</option>
              {selectedPoll && <option value="closed">Expired</option>}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-2 mb-3">
          <Form.Group controlId="formStatus">
            <Form.Label>Type*</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={pollData.type}
              onChange={(e) =>
                setPollData({ ...pollData, type: e.target.value })
              }
              required
            >
              <option value="poll">Poll</option>
              <option value="vote">Vote</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4 mb-3">
          <Form.Group controlId="formAllowMultipleVotes">
            <Form.Check
              type="checkbox"
              label="Allow Multiple Votes"
              checked={pollData.allow_multiple_votes}
              onChange={(e) =>
                setPollData({
                  ...pollData,
                  allow_multiple_votes: e.target.checked,
                })
              }
            />
          </Form.Group>
        </div>
      </div>

      <h5 className="mt-4 mb-3 font-bold">Options</h5>
      {pollData.options.map((option, index) => (
        <div key={index}>
          <Form.Group
            controlId={`formOptionTitle${index}`}
            style={{ marginTop: "40px" }}
          >
            <Form.Label>Option Title*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter option title"
              name="title"
              value={option.title}
              onChange={(e) => handleChange(e, index)}
              className="mb-3"
              required
            />
          </Form.Group>
          <Form.Group controlId={`formOptionDescription${index}`}>
            <Form.Label>Option Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter option description"
              name="description"
              value={option.description}
              onChange={(e) => handleChange(e, index)}
              className="mb-3"
            />
          </Form.Group>
          <div style={{ float: "right", marginBottom: "10px" }}>
            {index === pollData.options.length - 1 && (
              <Button
                variant="success"
                onClick={addOption}
                size="sm"
                style={{ marginRight: "4px" }}
              >
                Add Option
              </Button>
            )}
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDeleteOption(index)}
            >
              Delete above Option
            </Button>
          </div>
        </div>
      ))}

      {pollData?.options?.length < 1 && (
        <div style={{ float: "right", marginBottom: "10px" }}>
          <Button
            variant="success"
            onClick={addOption}
            size="sm"
            style={{ marginRight: "4px" }}
          >
            Add Option
          </Button>
        </div>
      )}

      {!selectedPoll ? (
        <Button
          variant="primary"
          type="submit"
          style={{ marginTop: "40px", marginBottom: "20px" }}
          disabled={
            !(
              pollData.title &&
              pollData.start_date &&
              pollData.end_date &&
              pollData.options
            )
          }
        >
          Create Poll/Vote
        </Button>
      ) : (
        <Button
          variant="primary"
          type="submit"
          style={{ marginTop: "40px", marginBottom: "20px" }}
          disabled={
            !(
              pollData.title &&
              pollData.start_date &&
              pollData.end_date &&
              pollData.options
            )
          }
          onClick={handleEditSubmit}
        >
          Save changes
        </Button>
      )}
    </Form>
  );
};

export default CreateAndEditPollForm;
