import React from "react";

const PrivacyPolicyView = () => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <p>
        <i>
          <strong>Effective Date:</strong> August 10, 2024
        </i>
      </p>

      <h4>1. Introduction</h4>
      <p>
        Welcome to Naija E-Democracy, a service provided by Cloudonesoftware LLC
        ("we," "us," "our"). We are committed to protecting your privacy and
        ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy explains how we collect, use,
        and share your information when you use our website and mobile
        application (the "Service").
      </p>

      <h4>2. Information We Collect</h4>
      <h5>a. Personal Information:</h5>
      <p>
        <strong>Account Information:</strong> When you register for an account,
        we collect your name, email address, and other relevant contact
        information.
      </p>
      <p>
        <strong>SSO Information:</strong> If you choose to sign in using Google
        or Facebook Single Sign-On (SSO), we collect the necessary
        authentication information and any additional information you consent to
        share with us via these services.
      </p>

      <h5>b. Usage Information:</h5>
      <p>
        <strong>Log Data:</strong> We automatically collect information about
        your interactions with the Service, such as your IP address, browser
        type, device information, and the pages you visit.
      </p>
      <p>
        <strong>App Usage Data:</strong> We collect information on how you use
        our mobile application, including the features you interact with and the
        duration of your sessions.
      </p>

      <h4>3. How We Use Your Information</h4>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>
          <strong>To Provide and Improve the Service:</strong> We use your
          information to operate and enhance the features and functionality of
          Naija E-Democracy.
        </li>
        <li>
          <strong>To Communicate with You:</strong> We may use your contact
          information to send you updates, notifications, and other important
          information about the Service.
        </li>
        <li>
          <strong>To Ensure Security:</strong> We use your information to
          protect against and prevent fraud, unauthorized access, and other
          security issues.
        </li>
      </ul>

      <h4>4. Sharing Your Information</h4>
      <p>
        We do not share your personal information with third parties, except in
        the following circumstances:
      </p>
      <ul>
        <li>
          <strong>With Your Consent:</strong> We may share your information when
          you explicitly consent to it.
        </li>
        <li>
          <strong>For Legal Reasons:</strong> We may disclose your information
          to comply with legal obligations, protect our rights, or respond to
          lawful requests from public authorities.
        </li>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who perform services on our behalf, such
          as hosting, maintenance, and support.
        </li>
      </ul>

      <h4>5. Your Choices and Rights</h4>
      <p>
        <strong>Account and Data Deletion:</strong> If you wish to delete your
        account or any personal data we have collected, please contact us at
        contact@cloudonesoftware.com.
      </p>

      <h4>6. Security</h4>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal information against unauthorized access,
        alteration, disclosure, or destruction. However, no method of
        transmission over the internet or electronic storage is completely
        secure, so we cannot guarantee absolute security.
      </p>

      <h4>7. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our website and
        updating the effective date. You are advised to review this Privacy
        Policy periodically for any changes.
      </p>

      <h4>8. Contact Us</h4>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, or if you wish to delete your account or personal
        data, please contact us at:
      </p>
      <p>
        Cloudonesoftware LLC
        <br />
        <strong>Email:</strong> contact@cloudonesoftware.com
      </p>

      <p>
        Thank you for using Naija E-Democracy. Your privacy is important to us,
        and we are committed to protecting your personal information.
      </p>
    </div>
  );
};

export default PrivacyPolicyView;
