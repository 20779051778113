import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { verifyUser } from "../actions/actions";
import { useNavigate } from "react-router-dom";

const VerificationForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");

  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    verifyUser(phoneNumber, nin, twoFactorCode)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            window.location.href =
              responseData?.redirect || "/user/dashboard/poll";
            setMessage({
              type: "success",
              text: "Please verify your account externally to complete verification",
            });
          });
        } else {
          response.json().then((responseData) => {
            if (responseData?.message.includes("Two factor")) {
              setMessage({
                type: "info",
                text: responseData?.message,
              });
              setShowTwoFactorInput(true);
            } else if (
              responseData?.message.includes("User verified successfully")
            ) {
              setMessage({
                type: "success",
                text: responseData?.message || "Verification successful",
              });
              navigate("/login");
            } else {
              setMessage({
                type: "info",
                text: responseData?.message || "Verification failed",
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during verification",
        });
      });
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formNin">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your NIN"
            name="nin"
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        {showTwoFactorInput && (
          <Form.Group controlId="formTwoFactorCode">
            <Form.Label>Two Factor Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter two factor code sent"
              name="twoFactorCode"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              style={{ marginBottom: "20px" }}
              required
            />
          </Form.Group>
        )}

        <Button
          variant="primary"
          type="submit"
          className="w-100"
          disabled={!nin || !phoneNumber}
        >
          Verify Account
        </Button>
      </Form>
    </>
  );
};

export default VerificationForm;
