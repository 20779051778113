import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet, Link, useLocation } from "react-router-dom";
import { logout, getJwtToken } from "../actions/actions";
import "./styles.css";

const WebsiteNavbar = () => {
  const location = useLocation();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  document.title = "NaijaE-Democracy " + process.env.REACT_APP_MODE;

  const jwtToken = getJwtToken();

  useEffect(() => {
    setIsUserLoggedIn(
      jwtToken ||
        location.pathname.includes("/dashboard") ||
        location.pathname.includes("/profile") ||
        location.pathname.includes("/chat") ||
        location.pathname.includes("/verification")
    );
  }, [location]);

  const handleLogout = () => {
    logout();
    localStorage.removeItem("jwtToken");
    setIsUserLoggedIn(false);
  };

  if (location.pathname.endsWith("/"))
    return (
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Navbar
        variant="dark"
        expand="lg"
        sticky="top"
        className="navbar-container"
        style={{ backgroundColor: "#708914" }}
      >
        <Container fluid className="mobile-padding-top">
          <Navbar.Brand href="/">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3>NaijaE-Democracy {" " + process.env.REACT_APP_MODE}</h3>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {isUserLoggedIn ? (
                <>
                  <Nav.Link as={Link} to="/user/dashboard/poll">
                    POLLING
                  </Nav.Link>
                  <Nav.Link as={Link} to="/user/dashboard/vote">
                    VOTING
                  </Nav.Link>
                  <Nav.Link as={Link} to="/chat">
                    LAW OF THE LAND
                  </Nav.Link>
                  <Nav.Link as={Link} to="/user/profile">
                    MY PROFILE
                  </Nav.Link>
                  <Nav.Link as={Link} to="/login" onClick={handleLogout}>
                    LOG OUT
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login">
                    LOG IN
                  </Nav.Link>
                  <Nav.Link as={Link} to="/signup">
                    SIGN UP
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div style={{ flex: 1 }} className="mobile-margin-bottom">
        <Outlet />
      </div>

      <footer
        style={{
          backgroundColor: "#708914",
          color: "#fff",
          textAlign: "center",
          padding: "10px 0",
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Container fluid>
          <p style={{ margin: 0 }}>
            &copy; {new Date().getFullYear()} Cloudonesoftware LLC. All rights
            reserved.
          </p>
        </Container>
      </footer>
    </div>
  );
};

export default WebsiteNavbar;
