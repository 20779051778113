import React from "react";
import { useNavigate } from "react-router-dom";

const PetroleumIndustryActView = () => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/chat");
  };

  return (
    <div style={{ marginBottom: "20px", padding: "20px" }}>
      <div style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "20px",
        backgroundColor: "#f9f9f9"
      }}>
        <h4 className="my-2"><strong>Petroleum Industry Act, 2021</strong></h4>
        <h6>Promotes governance, transparency, and efficiency in the Nigerian petroleum sector while supporting community development.</h6>
        <p>
          <strong>CHAPTER I - Governance Framework</strong><br />
          <strong>1. Regulatory Bodies:</strong> Establishes Nigerian Upstream Regulatory Commission for oversight.<br />
          <strong>2. Licensing & Compliance:</strong> New guidelines for licenses and resource management.
        </p>
        <p>
          <strong>CHAPTER II - Community Development</strong><br />
          <strong>3. Host Community Trust:</strong> Ensures benefits for communities affected by petroleum operations.<br />
          <strong>4. Environmental Standards:</strong> Enforces environmental safety standards and practices.
        </p>
        <button
          onClick={handleMoreClick}
          style={{ margin: "auto", padding: "10px 20px", backgroundColor: "#708914", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer" }}
        >
          Read More
        </button>
      </div>
    </div>
  );
};

export default PetroleumIndustryActView;
