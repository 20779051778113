import React, { useEffect, useState } from "react";
import { verifyJWTToken } from "../actions/actions";

const RedirectionPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTokenFromUrl = () => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get("param");
    };

    const checkIsReturnToApp = () => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get("return") == "true";
    };

    const checkIsBrowserOriginatedRequest = () => {
      const isBrowserRequest = localStorage.getItem("is_browser_originated_request") === "true";
      if (isBrowserRequest) localStorage.removeItem('is_browser_originated_request');
      return isBrowserRequest;
    };

    const accessToken = getTokenFromUrl();
    const isReturnToApp = checkIsReturnToApp();
    const isBrowserOriginatedRequest = checkIsBrowserOriginatedRequest();

    const verifyToken = async () => {
      try {
        const response = await verifyJWTToken(accessToken);

        if (response.ok) {
          if (
            /iPhone|iPad|iPod/.test(navigator.userAgent) &&
            !isBrowserOriginatedRequest &&
            !isReturnToApp
          ) {
            window.location.href = `com.yourapp.identifier://redirection?param=${accessToken}&return=true`;
          } else {
            localStorage.setItem("jwtToken", accessToken);
            window.location.href = "/chat";
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setLoading(false);
      }
    };

    setTimeout(() => {
      if (accessToken) {
        verifyToken();
      } else {
        setLoading(false);
      }
    }, 2000);
  }, []);

  return (
    <div>
      {loading ? (
        <h5 className="m-10 text-center">
          <i className="fas fa-spinner fa-spin mx-2"></i> Loading...
        </h5>
      ) : (
        <div style={{ margin: "auto", color: "red" }} className="text-center">
          <h5>Failed to login user, please try again.</h5>
        </div>
      )}
    </div>
  );
};

export default RedirectionPage;
