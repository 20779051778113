import React from "react";
import { useNavigate } from "react-router-dom";

const ConstitutionView = () => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/chat");
  };

  return (
    <div style={{ marginBottom: "20px", padding: "20px" }}>
      <div style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "20px",
        backgroundColor: "#f9f9f9"
      }}>
        <h4 className="my-2"><strong>The Constitution of the Federal Republic of Nigeria</strong></h4>
        <h6>Updated with the First, Second, Third, Fourth, and Fifth Alterations</h6>
        <p>
          <strong>CHAPTER I - General Provisions</strong><br />
          <strong>PART I - Federal Republic of Nigeria</strong><br />
          <strong>1. Supremacy of the Constitution</strong><br />
          (1) This Constitution is supreme and its provisions shall have binding force on all 
          authorities and persons throughout the Federal Republic of Nigeria.<br />
          (2) The Federal Republic of Nigeria shall not be governed, nor shall any persons 
          or group of persons take control of the Government of Nigeria or any part thereof, 
          except in accordance with the provisions of this Constitution.
          (3) If any other law is inconsistent with the provisions of this
          Constitution, this Constitution shall prevail, and that other law shall
          to the extent of the inconsistency be void.
        </p>
        <p>
          <strong>2. The Federal Republic of Nigeria</strong><br />
          (1) Nigeria is one indivisible and indissoluble Sovereign State to be known by the 
          name of the Federal Republic of Nigeria.<br />
          (2) Nigeria shall be a Federation consisting of States and a Federal Capital Territory.
        </p>
        <p>
          <strong>3. States of the Federation and the Federal Capital Territory</strong><br />
          There shall be thirty-six States in Nigeria, that is to say, Abia, Adamawa, Akwa Ibom, 
          Anambra, Bauchi, Bayelsa, Benue, Borno, Cross River, Delta, Ebonyi, Edo, Ekiti, Enugu, 
          Gombe, Imo, Jigawa, Kaduna, Kano, Katsina, Kebbi, Kogi, Kwara, Lagos, Nasarawa, 
          Niger, Ogun, Ondo, Osun, Oyo, Plateau, Rivers, Sokoto, Taraba, Yobe, and Zamfara.
          .....
          .....
        </p>
        <button
          onClick={handleMoreClick} 
          style={{ margin:"auto", padding: "10px 20px", backgroundColor: "#708914", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer", alignContent: "center" }}>
          Read More
        </button>
      </div>
    </div>
  );
};

export default ConstitutionView;
