import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import {
  forgetPasswordEmail,
  emailLogin,
  getJwtToken,
  googleSSOAuth,
  facebookSSOAuth,
  appleSSOAuth,
} from "../actions/actions";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [nin, setNin] = useState("");
  // const [twoFactorCode, setTwoFactorCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  // const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const isRedirectedFromVeriff = window.location.search.includes("veriff");

    if (isRedirectedFromVeriff) {
      setMessage({
        type: "info",
        text: "If you completed the verification step, then it will take some time to reflect. And if you did not complete the verification, please signup again.",
      });
    }

    const jwtToken = getJwtToken();
    if (jwtToken) navigate("/home");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    CapacitorApp.addListener("appUrlOpen", (data) => {
      if (data.url) {
        const url = data.url;

        if (url.includes("redirection")) {
          const startIndex = url.indexOf("redirection");
          const redirectionRoute = url.substring(startIndex);

          navigate(`/${redirectionRoute}`);
        }
      }
    });
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailLogin(email, password)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            localStorage.setItem("jwtToken", responseData?.access_token);
            setMessage({
              type: "success",
              text: responseData?.message || "Login successful",
            });
            if (responseData?.role === "admin") navigate("/admin/dashboard");
            else navigate("/home");
          });
        } else {
          response.json().then((responseData) => {
            if (responseData?.message.includes("Two factor")) {
              setMessage({
                type: "info",
                text: responseData?.message,
              });
              //setShowTwoFactorInput(true);
            } else {
              setMessage({
                type: "danger",
                text: responseData?.message || "Login failed",
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during login",
        });
      });
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();

    forgetPasswordEmail(email)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            setMessage({
              type: "success",
              text:
                responseData?.message ||
                "Password reset email sent to your registered email.",
            });
          });
        } else {
          response.json().then((responseData) => {
            setMessage({
              type: "danger",
              text:
                responseData?.message || "Unable to send password reset email",
            });
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during password reset",
        });
      });
  };

  const handleGoogleLogin = () => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      const isBrowser = !Capacitor.isNativePlatform();

      if (isBrowser) {
        localStorage.setItem("is_browser_originated_request", "true");
      }
    }

    googleSSOAuth()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.auth_url) {
          window.location.href = data.auth_url;
        } else {
          setMessage({
            type: "danger",
            text: "Some error occurred during login",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during login",
        });
      });
  };

  const handleFacebookLogin = () => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      const isBrowser = !Capacitor.isNativePlatform();

      if (isBrowser) {
        localStorage.setItem("is_browser_originated_request", "true");
      }
    }

    facebookSSOAuth()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.auth_url) {
          window.location.href = data.auth_url;
        } else {
          setMessage({
            type: "danger",
            text: "Some error occurred during signup",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  const handleAppleLogin = () => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      const isBrowser = !Capacitor.isNativePlatform();

      if (isBrowser) {
        localStorage.setItem("is_browser_originated_request", "true");
      }
    }

    appleSSOAuth()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.auth_url) {
          window.location.href = data.auth_url;
        } else {
          setMessage({
            type: "danger",
            text: "Some error occurred during signup",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        {/* <Form.Group controlId="formPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formNin">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your NIN"
            name="nin"
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        {showTwoFactorInput && (
          <Form.Group controlId="formTwoFactorCode">
            <Form.Label>Two Factor Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter two factor code sent"
              name="twoFactorCode"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              style={{ marginBottom: "20px" }}
              required
            />
          </Form.Group>
        )} */}

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your email"
            name="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <div className="form-check ml-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={handleTogglePassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show Password
            </label>
          </div>
        </Form.Group>

        <p
          className="text-end"
          style={{ cursor: "pointer", color: "#708914" }}
          onClick={handleForgetPassword}
        >
          Forgot Password
        </p>

        <button
          type="submit"
          className="w-100 mt-1"
          style={{
            padding: "8px",
            margin: "auto",
            backgroundColor: "#708914",
            borderRadius: "10px",
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            border: "none",
          }}
        >
          Login
        </button>

        <div className="text-center mt-3">
          <p>Or continue with</p>
          <div
            className="d-flex w-100 justify-content-around gap-2"
            style={{ margin: "0px" }}
          >
            <button
              style={{
                width: "50%",
                margin: "0px",
                padding: "8px",
                fontWeight: "bold",
                backgroundColor: "#708914",
                borderRadius: "10px",
                cursor: "pointer",
                border: "none",
                color: "white",
              }}
              onClick={handleGoogleLogin}
            >
              <i className="fab fa-google me-2" />
              Google
            </button>
            <button
              style={{
                width: "50%",
                margin: "0px",
                padding: "8px",
                fontWeight: "bold",
                backgroundColor: "#708914",
                borderRadius: "10px",
                cursor: "pointer",
                border: "none",
                color: "white",
              }}
              onClick={handleFacebookLogin}
            >
              <i className="fab fa-facebook me-2" />
              Facebook
            </button>
            <button
              style={{
                width: "50%",
                margin: "0px",
                padding: "8px",
                fontWeight: "bold",
                backgroundColor: "#708914",
                borderRadius: "10px",
                cursor: "pointer",
                border: "none",
                color: "white",
              }}
              onClick={handleAppleLogin}
            >
              <i className="fab fa-apple me-2" />
              Apple
            </button>
          </div>
        </div>
      </Form>

      <p className="text-center mt-3" style={{ color: "gray" }}>
        <i>
          By using our services, you confirm that you've read, understood, and
          agree to our{" "}
          <a href="/privacy-policy" style={{ color: "#708914" }}>
            Privacy Policy
          </a>
          .
        </i>
      </p>
    </>
  );
};

export default LoginForm;
