import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WebsiteNavbarLayout from "./components/WebsiteNavbar";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import AdminDashboard from "./pages/AdminDashboard";
import UserDashboard from "./pages/UserDashboard";
import ResetPassword from "./pages/ResetPassword";
import ChatDashboard from "./pages/ChatDashboard";
import LandingPage from "./pages/LandingPage";
import RedirectPage from "./pages/RedirectionPage";
import VerificationPage from "./pages/VerificationPage";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";

import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import UserProfilePage from "./pages/UserProfilePage";
import ConstitutionPage from "./pages/ConstitutionPage";
import PoliceActPage from "./pages/PoliceActPage";
import ElectoralActPage from "./pages/ElectoralActPage";
import PIAPage from "./pages/PIAPage";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WebsiteNavbarLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route path="user/dashboard/vote" element={<UserDashboard type={"Votes"}/>} />
          <Route path="user/dashboard/poll" element={<UserDashboard type={"Polls"} />} />
          <Route
            path="reset-password/:reset_password_token"
            element={<ResetPassword />}
          />
          <Route path="chat" element={<ChatDashboard />} />
          <Route path="redirection" element={<RedirectPage />} />
          <Route path="verification" element={<VerificationPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="user/profile" element={<UserProfilePage />} />
          <Route path="read/constitution" element={<ConstitutionPage/>} />
          <Route path="read/police-act" element={<PoliceActPage />} />
          <Route path="read/electoral-act" element={<ElectoralActPage/>} />
          <Route path="read/pia" element={<PIAPage />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
