import React, { useState, useEffect, useRef } from "react";
import { fetchUserChats, askQuestion, getJwtToken } from "../actions/actions";

const Dashboard = () => {
  const [conversations, setConversations] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [chatError, setChatError] = useState("");
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (!getJwtToken()) {
      window.location.href = "/login";
    }

    fetchChatData();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  const fetchChatData = async () => {
    setChatError("");

    try {
      const response = await fetchUserChats();

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const { user_conversation } = data;

      if (user_conversation && user_conversation.length > 0) {
        setConversations(user_conversation);
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
      setChatError(
        error.message || "Failed to fetch chat data. Please try again."
      );
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setSendingMessage(true);
    setChatError("");

    // Temporary message to display before the actual response
    const tempMessage = {
      user_query: newMessage,
      ai_response: "Processing query...",
      timestamp: new Date().toISOString(),
    };

    // Add the temporary message to the chat
    const tempConversations = [...conversations, tempMessage];
    setConversations(tempConversations);
    setNewMessage("");

    try {
      const response = await askQuestion(newMessage);

      if (!response.ok) {
        throw new Error(
          `Failed to send message. Please try again: ${response.status}`
        );
      }

      const responseData = await response.json();

      // Update the last message (temporary message) with the actual response
      const updatedConversations = tempConversations.map((msg, index) =>
        index === tempConversations.length - 1
          ? { ...msg, ai_response: responseData.message }
          : msg
      );

      setConversations(updatedConversations);
    } catch (error) {
      console.error("Error sending message:", error);
      setChatError(
        error.message || "Failed to send message. Please try again."
      );

      // Update the last message with an error if the API call fails
      const updatedConversations = tempConversations.map((msg, index) =>
        index === tempConversations.length - 1
          ? { ...msg, ai_response: "Error processing message." }
          : msg
      );

      setConversations(updatedConversations);
    } finally {
      setSendingMessage(false);
    }
  };

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="main-container">
      <div className="inner-container">
        <div className="chat-container">
          {conversations?.length > 0 ? (
            conversations?.map((msg, index) => (
              <div key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    backgroundColor: "#556B2F",
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                    textAlign: "end",
                    alignSelf: "right",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>{msg.user_query}</p>
                </div>
                {msg.ai_response && (
                  <div
                    style={{
                      backgroundColor: "#4A5568",
                      color: "#CBD5E0",
                      padding: "10px",
                      borderRadius: "8px",
                      marginTop: "10px",
                      alignSelf: "flex-start",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>{msg.ai_response}</p>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#A0AEC0",
                    marginTop: "5px",
                  }}
                >
                  {msg.timestamp}
                </p>
              </div>
            ))
          ) : (
            <p style={{ color: "#E2E8F0" }}>
              No conversation history exists...
            </p>
          )}
          <div ref={chatEndRef} />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            placeholder="Ask a question..."
            style={{
              flexGrow: 1,
              padding: "10px",
              borderRadius: "4px 0 0 4px",
              outline: "none",
            }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !sendingMessage) {
                handleSendMessage();
              }
            }}
          />
          <button
            style={{
              backgroundColor: "#556B2F",
              color: "#FFFFFF",
              padding: "10px 20px",
              borderRadius: "0 4px 4px 0",
              marginLeft: "10px",
              cursor: "pointer",
              border: "none",
            }}
            onClick={handleSendMessage}
            disabled={sendingMessage}
          >
            {sendingMessage ? "..." : "Send"}
          </button>
        </div>

        <p className="text-white text-center mt-4" style={{ fontSize: "12px" }}>
          {" "}
          *All responses are based on the Constitution of the Federal Republic
          of Nigeria (1999) as amended by the 1st, 2nd, and 3rd alterations
          (2010), the 4th alteration (2017), and the 5th alteration (2023); or
          the Nigerian Police Act (2020); or the Electoral Act (2022); or the
          Petroleum Industry Act (2021).
        </p>

        <p className="text-white text-center mt-4 hide-on-mobile">
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => window.open("/Constitution_watermark.pdf", "_blank")}
          >
            View Constitution
          </span>
          {" | "}
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() =>
              window.open("./NigerianPoliceAct2020_watermark.pdf", "_blank")
            }
          >
            View Police Act
          </span>
          {" | "}
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() =>
              window.open("./Electoral-Act-2022_watermark.pdf", "_blank")
            }
          >
            View Electoral Act
          </span>
          {" | "}
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => window.open("./PIA-2021_watermark.pdf", "_blank")}
          >
            View PIA
          </span>
        </p>

        {chatError && (
          <p style={{ color: "#E53E3E", marginTop: "5px" }}>{chatError}</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
