import React from "react";
import { useNavigate } from "react-router-dom";

const ElectoralActView = () => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/chat");
  };

  return (
    <div style={{ marginBottom: "20px", padding: "20px" }}>
      <div style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "20px",
        backgroundColor: "#f9f9f9"
      }}>
        <h4 className="my-2"><strong>Electoral Act, 2022</strong></h4>
        <h6>Provides a legal framework for federal, state, and area council elections in Nigeria, ensuring transparency and fairness.</h6>
        <p>
          <strong>CHAPTER I - Establishment and Powers</strong><br />
          <strong>1. INEC Authority:</strong> INEC oversees election administration and voter registration.<br />
          <strong>2. Voter Eligibility:</strong> Continuous voter registration and verification guidelines are provided.
        </p>
        <p>
          <strong>CHAPTER II - Election Procedures</strong><br />
          <strong>3. Election Conduct:</strong> Defines rights and obligations of voters and election staff.<br />
          <strong>4. Security Measures:</strong> Mandates measures for secure and peaceful elections.
        </p>
        <button
          onClick={handleMoreClick}
          style={{ margin: "auto", padding: "10px 20px", backgroundColor: "#708914", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer" }}
        >
          Read More
        </button>
      </div>
    </div>
  );
};

export default ElectoralActView;
