import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ElectoralAct from "../components/ElectoralActView";
import logoImage from "../components/images/logo.png";

const ElectoralActPage = () => (
  <Container className="my-5">
    <Row className="justify-content-around align-items-start flex-column flex-md-row">
      <Col xs={12} md={4} className="d-flex justify-content-center">
        <img src={logoImage} alt="Logo" className="login-logo" />
      </Col>

      <Col xs={12} md={6}>
        <h2 className="mb-4 text-center text-md-left">Police Act</h2>
        <ElectoralAct />
      </Col>
    </Row>
  </Container>
);

export default ElectoralActPage;
