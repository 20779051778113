import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorView = () => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/chat");
  };

  return (
    <div style={{ margin: "auto", color: "red" }} className="text-center">
      <h6>Something went wrong during the authentication. Please try again.</h6>
    </div>
  )
}

export default ErrorView;
